import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import {
  Button,
  Logo,
  TextButton,
  Typography,
  Warning3DIcon,
} from "@vapaus/ui-v2";

// TODO: Import LanguageSelectV2 from @vapaus/i18n after LanguageSelect has been completely removed to avoid importing legacy styles.
import { LanguageSelectV2 } from "../../../../i18n/src/LanguageSelectV2";

type ButtonProps = {
  text: string;
  onClick: () => void;
};

type ErrorPageProps = {
  title: string;
  description: string;
  primaryButton: ButtonProps;
  secondaryButton?: ButtonProps;
};

export function ErrorPage({
  title,
  description,
  primaryButton,
  secondaryButton,
}: ErrorPageProps) {
  return (
    <StyledRoot>
      <Header>
        <Link to="/">
          <Logo />
        </Link>
        <LanguageSelectV2 />
      </Header>
      <StyledContent>
        <Warning3DIcon size="lg" />
        <div>
          <Typography variant="h1" align="center" fullWidth marginBottom="sm">
            {title}
          </Typography>
          <Typography variant="bodyLg" align="center" fullWidth>
            {description}
          </Typography>
        </div>
        <Button
          onClick={primaryButton.onClick}
          variant="primary1"
          marginBottom="sm"
          marginTop="sm"
          fullWidth
        >
          {primaryButton.text}
        </Button>
        {secondaryButton ? (
          <TextButton onClick={secondaryButton.onClick} align="center">
            {secondaryButton.text}
          </TextButton>
        ) : null}
      </StyledContent>
    </StyledRoot>
  );
}

const StyledRoot = styled.div`
  min-height: 100vh;
  position: relative;
  background-color: ${({ theme }) => theme.palette.secondary2.main};
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  height: ${({ theme }) => theme.height.navBar};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      border-bottom: ${({ theme }) => theme.border.secondary.main};
    `)}
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 432px;
  max-width: 100%;
  margin: auto;
  gap: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.md}`};
`;
