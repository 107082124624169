import { RouteObject } from "react-router-dom";

import { apiConfiguration } from "./api";
import { Auth } from "./components";
import { CreatePasswordPage } from "./components/CreatePasswordPage";
import { ForgotPasswordPage } from "./components/ForgotPasswordPage";
import { ResetPasswordPage } from "./components/ResetPasswordPage";
import { SignInPage } from "./components/SignInPage";
import { SignUpPage } from "./components/SignUpPage";
import { TermsOfServicePage } from "./components/TermsOfServicePage";
import { TermsOfServiceUpdated } from "./components/TermsOfServicePage/TermsOfServiceUpdated";
import { VerifyEmailPage } from "./components/VerifyEmailPage";
import { VerifyingEmailPage } from "./components/VerifyingEmailPage/VerifyingEmailPage";
import { EmailVerificationLinkExpiredErrorPage } from "./components/errors/EmailVerificationLinkExpiredErrorPage";
import { GenericErrorPage } from "./components/errors/GenericErrorPage";
import { NoResponseErrorPage } from "./components/errors/NoResponseErrorPage";
import { ResetPasswordLinkExpiredErrorPage } from "./components/errors/ResetPasswordLinkExpired";
import { AppName, AvailableScopes } from "./constants";

export interface CreateAuthRoutesOptions {
  allowSignUp?: boolean;
  scope?: AvailableScopes;
  app: AppName;
}

const clearTokenOnRoute = () => {
  apiConfiguration.clearToken();
  return null;
};

export function createAuthRoutes(
  { allowSignUp, scope, app }: CreateAuthRoutesOptions,
  appRoutes: Array<RouteObject>,
): Array<RouteObject> {
  const routes = [
    {
      path: "/",
      element: <Auth app={app} />,
      children: [
        {
          path: "forgot-password",
          element: <ForgotPasswordPage />,
        },
        {
          path: "reset-password",
          loader: clearTokenOnRoute,
          element: <ResetPasswordPage />,
        },
        {
          path: "sign-in",
          element: <SignInPage allowSignUp={allowSignUp} scope={scope} />,
        },
        {
          path: "set-password",
          loader: clearTokenOnRoute,
          element: <CreatePasswordPage app={app} />,
        },
        {
          path: "terms",
          element: <TermsOfServiceUpdated app={app} />,
        },
        {
          path: "verify-email",
          element: <VerifyingEmailPage />,
        },
        {
          path: "email-not-verified",
          element: <VerifyEmailPage />,
        },
        {
          path: "terms-of-service",
          element: <TermsOfServicePage app={app} />,
        },
        ...(allowSignUp
          ? [
              {
                path: "sign-up",
                element: <SignUpPage app={app} />,
              },
            ]
          : []),
        ...appRoutes,
      ],
    },
    {
      path: "email-verification-link-expired",
      element: <EmailVerificationLinkExpiredErrorPage />,
    },
    {
      path: "reset-password-link-expired",
      element: <ResetPasswordLinkExpiredErrorPage />,
    },
    {
      path: "no-response",
      element: <NoResponseErrorPage />,
    },
    {
      path: "*",
      // TODO: Make a 404 page instead of an error page
      element: <GenericErrorPage />,
    },
  ];

  return routes;
}
