import { useMutation, useQuery } from "@tanstack/react-query";

import { AuthApi } from "@vapaus/api-codegen";
import type {
  AuthResetPasswordRequest,
  AuthSignInRequest,
  AuthSignUpRequest,
  Msg,
  PasswordResetToken,
  ResponseError,
  Token,
} from "@vapaus/api-codegen";

import { apiConfiguration } from "./_configuration";

export const authApi = new AuthApi(apiConfiguration);

export type PasswordResetInput = PasswordResetToken & { email: string };

export const useSignIn = () =>
  useMutation<Token, ResponseError, AuthSignInRequest>(
    (data: AuthSignInRequest) => authApi.authSignIn(data),
  );

export const useSignUp = () =>
  useMutation<Token, ResponseError, AuthSignUpRequest>(
    (data: AuthSignUpRequest) => authApi.authSignUp(data),
  );

export const useRecoverPassword = () =>
  useMutation<Msg, ResponseError, string>((email: string) =>
    authApi.authRecoverPassword({ email }),
  );

export const useResetPassword = () =>
  useMutation<Token, ResponseError, AuthResetPasswordRequest>(
    (data: AuthResetPasswordRequest) => authApi.authResetPassword(data),
  );

export const useCheckPasswordResetToken = (token?: string, email?: string) => {
  return useQuery<Msg, ResponseError>(
    ["checkPasswordResetToken", token, email],
    () =>
      authApi.authCheckResetPasswordToken({
        token: token!,
        email: email!,
      }),
    {
      enabled: Boolean(token && email),
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};
