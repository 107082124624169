import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ErrorPage } from "./../ErrorPage";

export function NoResponseErrorPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLogIn = () => navigate("/sign-in");

  return (
    <ErrorPage
      title={t("authV2:error.noResponseErrorPage.title")}
      description={t("authV2:error.noResponseErrorPage.description")}
      primaryButton={{
        text: t("authV2:error.noResponseErrorPage.logIn"),
        onClick: handleLogIn,
      }}
    />
  );
}
