import { useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { ErrorWithCodeResponse, FetchError } from "@vapaus/api-codegen";
import { Spinner } from "@vapaus/ui-v2";

import { useVerifyUserEmail } from "../../api";
import { useAuthContext } from "../../context";

export function VerifyingEmailPage() {
  const navigate = useNavigate();
  const { setToken } = useAuthContext();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") ?? "";
  const resumePath = searchParams.get("resume_path") ?? null;
  const { mutate: verifyEmail } = useVerifyUserEmail();
  const queryClient = useQueryClient();

  useEffect(() => {
    verifyEmail(token, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ["currentUserEmails"],
        });
        navigate(resumePath ?? "/");
      },
      onError: async (error) => {
        setToken(null);
        if (error instanceof FetchError) {
          navigate("/no-response");
        }
        const _error = (await error?.response?.json()) as ErrorWithCodeResponse;
        if (_error.code === "VERIFICATION_TOKEN_INVALID") {
          navigate("/email-verification-link-expired");
          return;
        }
        navigate("/error");
      },
    });
  }, [navigate, queryClient, resumePath, setToken, token, verifyEmail]);

  return (
    <StyledRoot>
      <Spinner />
    </StyledRoot>
  );
}

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
`;
