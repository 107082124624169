import { BikeBenefitContractState } from "@vapaus/api-codegen";
import { PaletteVariants } from "@vapaus/ui-v2";

export function mapBackgroundColorByContractState(
  state?: BikeBenefitContractState,
): PaletteVariants {
  switch (state) {
    case BikeBenefitContractState.Scheduled:
    case BikeBenefitContractState.ActivePendingRevision:
    case BikeBenefitContractState.ActivePendingCorrection:
    case BikeBenefitContractState.ActivePendingRedemption:
    case BikeBenefitContractState.ActivePendingReturn:
      return "secondary3.lighter";
    case BikeBenefitContractState.Active:
      return "secondary1.lighter";
    case BikeBenefitContractState.Cancelled:
    case BikeBenefitContractState.CancelledPendingPayment:
    case BikeBenefitContractState.Ended:
    case BikeBenefitContractState.Invalidated:
      return "common.white";
    case BikeBenefitContractState.EndedPendingPayment:
      return "secondary2.lighter";
    default:
      return "neutral.lighter";
  }
}
