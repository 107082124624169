import { useEffect, useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as zod from "zod";

import { FetchError, Token } from "@vapaus/api-codegen";
import { useFormMutation } from "@vapaus/form";

import {
  authApi,
  useAcceptAppTerms,
  useCurrentAppTerm,
  useVerifyUserEmail,
} from "../../api";
import { AppName } from "../../constants";
import { useAuthContext } from "../../context";

const useVerifyEmailToken = () => {
  const [searchParams] = useSearchParams();
  const emailVerifyToken = searchParams.get("email_verify_token");
  const verifyEmail = useVerifyUserEmail();
  const isRequestSent = verifyEmail.isIdle && !verifyEmail.data;
  const navigate = useNavigate();

  useEffect(() => {
    if (emailVerifyToken && isRequestSent) {
      verifyEmail.mutate(emailVerifyToken, {
        onError: (error) => {
          if (error instanceof FetchError) {
            navigate("/no-response");
          }
        },
      });
    }
  }, [emailVerifyToken, isRequestSent, navigate, verifyEmail]);
};

export const useCreatePasswordPage = (app: AppName) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setToken } = useAuthContext();

  const { data: appUserTerms } = useCurrentAppTerm(app, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: app === "user",
  });
  const acceptAppTerms = useAcceptAppTerms(app);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") ?? "";
  const email = searchParams.get("email") ?? "";

  const setPasswordDataSchema = useMemo(
    () =>
      zod.object({
        email: zod.string().nonempty(),
        token: zod.string().nonempty(),
        acceptTerms:
          app === "user" ? zod.literal(true) : zod.boolean().optional(),
        newPassword: zod
          .string()
          .nonempty(t("authV2:signIn.enterPassword"))
          .min(10, t("authV2:signIn.passwordTooShort")),
      }),
    [t, app],
  );

  type SetPasswordDataType = zod.infer<typeof setPasswordDataSchema>;

  useVerifyEmailToken();
  useEffect(() => {
    if (!token || !email) navigate("/sign-in");
  }, [token, email, navigate]);

  return useFormMutation<SetPasswordDataType, Token>(
    {
      mutationFn: ({ newPassword, token, email }) =>
        authApi.authResetPassword({
          email,
          passwordResetToken: {
            token,
            newPassword,
          },
        }),
      async onSuccess(res) {
        setToken(res.accessToken);
        if (app === "user" && appUserTerms)
          await acceptAppTerms.mutateAsync(appUserTerms.id);
        navigate("/");
      },
    },
    {
      resolver: zodResolver(setPasswordDataSchema),
      defaultValues: {
        token,
        email,
      },
    },
  );
};
